import request from '@/utils/request'
// 知情管理
const consentApi = {
  consentList: '/boss/pad/pro/icf/list',
  consentDetail: '/boss/pad/pro/get',
  detail: '/boss/pad/pro/icf/detail',
  getSignUrl: '/boss/pad/pro/getSignUrl',
  applyRefuse: '/boss/pad/pts/apply/refuse'
}

// 知情文件详情
export function getDetail (parameter) {
  return request({
    url: consentApi.detail,
    method: 'get',
    params: parameter
  })
}

// ICF签署列表
export function getConsentList (parameter) {
  return request({
    url: consentApi.consentList,
    method: 'get',
    params: parameter
  })
}
// 签署详情
export function getConsentDetail (parameter) {
  return request({
    url: consentApi.consentDetail,
    method: 'get',
    params: parameter
  })
}

// 获取签署链接
export function getSignUrl (parameter) {
  return request({
    url: consentApi.getSignUrl,
    method: 'post',
    data: parameter
  })
}

export function getApplyRefuse (parameter) {
  return request({
    url: consentApi.applyRefuse,
    method: 'get',
    params: parameter
  })
}

export function upload (parameter) {
  return request({
    url: '/common/upload',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: parameter
  })
}

export function applyPaper (parameter) {
  return request({
    url: '/boss/icf/applyPaper',
    method: 'post',
    data: parameter
  })
}

export function getApplyPaper (parameter) {
  return request({
    url: '/boss/icf/getApplyPaper',
    method: 'get',
    params: parameter
  })
}
